<template>
  <div>
    <div class="mb-3">
      <b-tabs align="center">
        <b-tab
          title="Existing Drivers"
          active
          @click="fetchSmarttruckUsers()"
        />
        <b-tab
          title="New Drivers"
          @click="fetchSmarttruckUsers('new')"
        />
      </b-tabs>
    </div>

    <my-table
      title=""
      :rows="rows"
      :columns="columns"
      :pagination="pagination"
      :is-loading="isLoading"
      :has-export="true"
      :exporting="exporting"
      @handleExport="handleExportUsers"
    >
      <template v-slot="{props}">
        <template
          v-if="
            props.column.field === 'id' ||
              props.column.field === 'status' ||
              props.column.field === 'action'
          "
        >
          <span v-if="props.column.field === 'id'">
            <b-button
              :to="{ name: 'driver', params: { id: props.row.id } }"
              block
              variant="danger"
            >
              {{ props.row.id }}
            </b-button>
          </span>

          <span v-if="props.column.field === 'status'">
            <b-button
              block
              :variant="props.row.status.variant"
              size="sm"
            >
              {{ props.row.status.text }}
            </b-button>
          </span>
          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  v-for="(status, index) in statuses"
                  :key="index"
                  @click.prevent="setStatus(status.value, props.row.id)"
                >
                  <feather-icon
                    :icon="status.icon"
                    class="mr-50"
                  />
                  <span>{{ status.text }}</span>
                </b-dropdown-item>
                <b-dropdown-item @click.prevent="handleDeleteUser(props.row.id)">
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>
        </template>
      </template>
    </my-table>
    <b-col cols="12 text-center">
      <b-button
        variant="danger"
        :to="{ name: 'dashboard' }"
        class="mr-1"
      >Back to Dashboard</b-button>
    </b-col>
  </div>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import axios from '@axios'
import { MyTable } from '@/components'

export default {
  components: {
    BDropdown,
    BDropdownItem,
    MyTable,
  },
  data() {
    return {
      isLoading: false,
      selected: null,
      statuses: [],
      columns: [
        {
          label: 'Drivers ID',
          field: 'id',
          thClass: 'text-left',
          tdClass: 'text-left',
          type: 'number',
        },
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Company Registration',
          field: 'company_registration',
        },
        {
          label: 'Status',
          field: 'status',
          thClass: 'text-left',
          tdClass: 'text-left',
          type: 'number',
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      pagination: {
        enabled: true,
        mode: 'records',
        perPage: 5,
        perPageDropdown: [5, 10, 15],
      },
      filterBy: '',
      exporting: false,
    }
  },
  created() {
    this.fetchSmarttruckUsers()
    this.fetchStatuses()
  },
  methods: {
    async fetchSmarttruckUsers(filter = '') {
      this.filterBy = filter
      this.isLoading = true
      const response = await axios.get(`/smarttruck-users?filter=${filter}`)
      if (response.status === 200) {
        this.rows = response.data
      }
      this.isLoading = false
    },
    async setStatus(status, userId) {
      const response = await axios.patch(`/user-statuses/${userId}`, { status })
      if (response.status === 200) {
        this.$swal({
          icon: 'success',
          title: 'Status Updated',
          text: 'Successfully Updated Driver Status',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
        this.fetchSmarttruckUsers(this.filterBy)
      }
    },
    async fetchStatuses() {
      const response = await axios.get('/user-statuses')
      if (response.status === 200) {
        this.statuses = response.data
      }
    },
    handleDeleteUser(userId) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          const response = await axios.delete(`/smarttruck-users/${userId}`)
          if (response.status === 200) {
            this.$swal({
              icon: 'success',
              title: 'Driver Deleted',
              text: 'Successfully Deleted',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            this.rows = this.rows.filter(user => user.id !== userId)
          }
        }
      })
    },
    async handleExportUsers(type) {
      this.exporting = true
      const response = await axios.get(`/smarttruck-users/export?filter=${this.filterBy}&file_type=${type}`, { responseType: 'blob' })
      const fileURL = window.URL.createObjectURL(new Blob([response.data]))
      const fileLink = document.createElement('a')
      fileLink.href = fileURL
      fileLink.setAttribute('download', `smarttruck-users-${new Date().getTime()}.${type}`)
      document.body.appendChild(fileLink)
      fileLink.click()
      this.exporting = false
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
